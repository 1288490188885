import React from "react"
import "./404.scss"

import NotFoundImg from "../images/404-static-image.png"

const NotFoundPage = ({ data, location }) => {
  return (
    <div className="wrapper-404">
      <div className="not-found">
        <div className="not-found-image">
          <img src={NotFoundImg} alt="404: Not found" />
        </div>
        <div class="not-found-content">
          <h1>Uh-oh!</h1>
          <p>
            We're having trouble finding the page you're looking for. Try going
            back to the <a href="/">homepage</a> or see our{" "}
            <a href="https://support.phonepe.com/">Help Center</a> for more
            information.
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
